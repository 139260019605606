<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Accueil</h1>
        </div>
        <GridCardBasic
            :data="messages"
            empty="Aucun message"
            minCardSize="400px"
        >
            <template v-slot="{ data }">
                <div class="k-card-3 p-3">
                    <div>
                        <h5>
                            <b-icon-megaphone-fill
                                class="text-kalkin-2 mr-2"
                            />{{ data.title }}
                        </h5>
                    </div>
                    <div class="k-pre text-justify p-1">{{ data.body }}</div>
                </div>
            </template>
        </GridCardBasic>

        <div class="k-flex-row">
            <h2>Mes derniers projets</h2>
            <div class="k-flex-line" />
        </div>

        <GridCardBasic :data="projects" empty="Aucun projet">
            <template v-slot="{ data }">
                <CardProjectMember :project="data" />
            </template>
            <template v-slot:last-card v-if="projects.length >= max">
                <div class="card-more" @click="goProjects">
                    <div>Voir tous mes projets</div>
                    <div><b-icon-arrow-right-circle scale="2" /></div>
                </div>
            </template>
        </GridCardBasic>
    </div>
</template>
<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardProjectMember from "@/components/model/project/CardProjectMember";

export default {
    components: {
        GridCardBasic,
        CardProjectMember,
    },

    data: function() {
        return {
            max: 4,
            messages: [
                {
                    title: "Nouvelle fonctionnalité",
                    body:
                        "Vous avez désormais la possibilité de consulter les informations relatives à vos installations, telles que leur localisation et l'état de leur connexion à nos services.\nRendez-vous sur le tableau de bord de vos configurations!",
                },
                {
                    title: "Bienvenue sur Kalkin-Tourisme !",
                    body:
                        "Vous bénéficiez d'un accès anticipé au back-office.\nDe nombreuses fonctionnalités sont encore en cours de développement, et nous souhaitons travailler main dans la main avec vous afin de vous fournir le meilleur service possible.",
                },
            ],
            projects: [],
            user: this.$store.state.user.user,
        };
    },

    methods: {
        goProjects() {
            this.$router.push({
                name: "Projects",
            });
        },
    },

    async beforeMount() {
        this.projects = await this.$store.dispatch(
            "project/getMyLastProjects",
            { n: this.max },
        );
    },
};
</script>

<style lang="scss" scoped>
.card-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    min-height: 200px;
    height: 100%;

    text-align: center;
    color: $primary-color;
    cursor: pointer;
    transition: color ease-in 0.2s;
}
.card-more:hover {
    color: $secondary-color;
}

.card-more:hover svg {
    animation: ease-in-out 0.3s infinite alternate shake;
}

@keyframes shake {
    from {
        transform: rotate(15deg);
    }
    to {
        transform: rotate(-15deg);
    }
}
</style>

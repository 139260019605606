<template>
    <div class="card k-card-2">
        <div class="k-flex-row">
            <div>
                <small class="text-kalkin-1">Nom</small>
                <div>
                    <b>{{ project.name }}</b>
                </div>
            </div>
        </div>

        <div class="k-flex-row k-flex-fill">
            <div>
                <small class="text-kalkin-1">
                    Mon rôle <b-icon-person-circle />
                </small>
                <div>
                    {{ project.ProjectUser.role }}
                </div>
            </div>

            <div>
                <small class="text-kalkin-1"> Mes droits <b-icon-key /> </small>
                <div>
                    {{ project.ProjectUser.credentials | projectRoleFromCreds }}
                </div>
            </div>
        </div>

        <div class="k-flex-row">
            <div>
                <small class="text-kalkin-1">Description</small>
                <div>
                    <em>{{ project.description }}</em>
                </div>
            </div>
        </div>

        <div class="flex-grow-1" />

        <div>
            <small class="text-kalkin-1">Date de création</small>
            <div>Le {{ project.createdAt | dateFromISO(true) }}</div>
        </div>

        <div class="k-flex-row k-flex-fill">
            <b-button
                size="sm"
                variant="kalkin-1"
                @click="goProjectDetails(project.id)"
            >
                <b-icon-check2-square />
                Choisir ce projet
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        project: Object,
    },

    methods: {
        goProjectDetails(id) {
            this.$router.push({
                name: "Project",
                params: { projectId: id },
            });
        },

        goProjectAdmin(id) {
            this.$router.push({
                name: "AdminProject",
                params: { projectId: id },
            });
        },
    },
};
</script>
